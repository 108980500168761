import React, { useEffect, useState } from "react";
import axios from "axios";
import { format } from "date-fns";
import Normal from "../../assets/normal.png";
import Slight from "../../assets/slight.png";
import Rain from "../../assets/rain.png";
import CareLeft from "../../assets/CareLeft.png";
import CareRight from "../../assets/CareRight.png";
import { ImSpinner9 } from "react-icons/im";
import "./Weather.css";

const Weather = () => {
  const [weatherData, setWeatherData] = useState(false);
  const [hourlyData, setHourlyData] = useState(false);
  const [weatherResponse, setWeatherResponse] = useState(false);
  const [forecastResponse, setForecastResponse] = useState(false);
  const [loading, setLoading] = useState(true);
  const [city, setCity] = useState("");

  useEffect(() => {
    const fetchLocationAndWeatherData = async () => {
      try {
        const useManualCoords = false; // Change to true to use manual location
        const latitude = 51.5074; // Example: London
        const longitude = -0.1278; // Example: London

        if (!useManualCoords && navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            async (position) => {
              const { latitude, longitude } = position.coords;
              await fetchWeatherData(latitude, longitude);
            },
            (error) => {
              console.error("Error getting geolocation", error);
              setLoading(false);
            }
          );
        } else {
          await fetchWeatherData(latitude, longitude);
        }
      } catch (error) {
        console.error("Error in fetching location and weather data", error);
        setLoading(false);
      }
    };

    const fetchWeatherData = async (latitude, longitude) => {
      console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);

      const openCageApiKey = "07543992cc8d4995948d9a6e3a3b8fbf";
      const geocodeUrl = `https://api.opencagedata.com/geocode/v1/json?q=${latitude},${longitude}&key=${openCageApiKey}`;
      console.log(`Geocode URL: ${geocodeUrl}`);

      const geocodeResponse = await axios.get(geocodeUrl);
      console.log("Geocode Response:", geocodeResponse.data);

      const components = geocodeResponse.data.results[0].components;
      let cityName =
        components.city ||
        components.town ||
        components.village ||
        components.state ||
        components.county;
      console.log("Geocode Components:", components);

      if (!cityName) {
        throw new Error("City name could not be determined from geocoding.");
      }

      // Handle specific case for Karachi
      if (cityName === "Karachi Division") {
        cityName = "Karachi";
      }

      setCity(cityName);
      console.log(`City: ${cityName}`);

      const apiKey = "579770f724ceef914f0d34ad8f8eed09";
      const weatherUrl = `https://api.openweathermap.org/data/2.5/weather?q=${cityName}&units=imperial&appid=${apiKey}`;
      const forecastUrl = `https://api.openweathermap.org/data/2.5/forecast?q=${cityName}&units=imperial&appid=${apiKey}`;

      console.log(`Weather URL: ${weatherUrl}`);
      console.log(`Forecast URL: ${forecastUrl}`);

      let weatherRes, forecastRes;

      try {
        weatherRes = await axios.get(weatherUrl);
        setWeatherResponse(weatherRes);
      } catch(e) {
        console.log("Error fetching weather.")
      }

      try {
        forecastRes = await axios.get(forecastUrl);
        setForecastResponse(forecastRes);
      } catch(e) {
        console.log("Error fetching forecast.")
      }

      console.log("Weather Response:", weatherResponse ? weatherResponse.data : "null");
      console.log("Forecast Response:", forecastResponse ? forecastResponse.data : "null");

      setWeatherData(forecastRes ? weatherRes.data : null);
      setHourlyData(forecastRes ? forecastRes.data.list.slice(0, 5) : null);
      setLoading(false);
    };

    fetchLocationAndWeatherData();
  }, []);

  const getWeatherIcon = (weatherCondition) => {
    switch (weatherCondition) {
      case "Clear":
        return Normal;
      case "Rain":
        return Rain;
      case "Drizzle":
        return Slight;
      case "Thunderstorm":
        return Rain;
      case "Snow":
        return Rain;
      case "Clouds":
        return Normal;
      default:
        return Normal;
    }
  };

  const formatDateTime = (date) => {
    const formattedTime = format(date, "h:mma");
    const formattedDay = format(date, "iiii");
    const formattedDate = format(date, "d MMMM");
    return `${formattedTime} • ${formattedDay} ${formattedDate}`;
  };

  const formatHour = (timestamp) => {
    return format(new Date(timestamp * 1000), "h a");
  };

  const fahrenheitToCelsius = (fahrenheit) => {
    return ((fahrenheit - 32) * 5) / 9;
  };

  if (loading) {
    return (
      <div className="loader2">
        <ImSpinner9 className="spinner2" />
      </div>
    );
  }

  if (!weatherData || !hourlyData || !weatherResponse || !forecastResponse) {
    console.log("let's see what's wrong: ", weatherData, hourlyData, weatherResponse, forecastResponse);
    return (
      <div>
        <p>Unable to fetch weather data. Please try again later.</p>
      </div>
    );
  }

  const { main, weather, name } = weatherData;
  const currentTempCelsius = Math.round(fahrenheitToCelsius(main.temp));
  const highTempCelsius = Math.round(fahrenheitToCelsius(main.temp_max));
  const lowTempCelsius = Math.round(fahrenheitToCelsius(main.temp_min));

  return (
    <div className="Weather">
      <div className="Weather-tag">
        <div>
          <h1>Weather</h1>
        </div>
        <div>
          <p>{formatDateTime(new Date())}</p>
        </div>
      </div>
      <hr />
      <div className="weather-header">
        <div className="weatherHead-pic">
          <img src={getWeatherIcon(weather[0].main)} alt="" />
        </div>
        <div>
          <h3>{currentTempCelsius}°C</h3>
        </div>
        <div className="weather-details">
          <p>{name}</p>
          <p>{weather[0].description}</p>
          <p>
            H:{highTempCelsius}° | L:{lowTempCelsius}°
          </p>
        </div>
      </div>
      <div className="Price-tag">
        <div>
          <h5>Hourly forecast</h5>
        </div>
        <div className="Arrow">
          {/* <div>
            <img src={CareLeft} alt="Previous" />
          </div>
          <div>
            <img src={CareRight} alt="Next" />
          </div> */}
        </div>
      </div>
      <div className="hourly-forecast">
        {hourlyData.map((hour, index) => (
          <div key={index} className="hour">
            <h4>{formatHour(hour.dt)}</h4>
            <img src={getWeatherIcon(hour.weather[0].main)} alt="" />
            <p>{Math.round(fahrenheitToCelsius(hour.main.temp))}°C</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Weather;
