import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "./ProgressBar.css";

const ProgressBar = ({ percentage }) => {
  return (
    <div style={{ width: 120, height: 120, position: "relative" }}>
      <CircularProgressbar
        value={percentage}
        text={`${percentage}%`}
        strokeWidth={12}
        className="progressbar"
        styles={buildStyles({
          pathColor: "#6464F5",
          textColor: "#4E4E73",
          trailColor: "#F0F0FE",
        })}
      />
      <div
      className="completed-text"
        style={{
          position: "absolute",
          top: "65%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        Completed
      </div>
    </div>
  );
};

export default ProgressBar;
