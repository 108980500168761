import React, { useState, useEffect } from "react";
import axios from "axios";
import "./OurPeople.css";
import Envelope from "../../assets/Envelope.png";
import LinkedinLogo from "../../assets/LinkedinLogo.png";
import { OurPeopleData } from "./OurPeopleData";
import { useDispatch, useSelector } from "react-redux";
import { ipv4 } from "../../utils/config";
import { useNavigate } from 'react-router-dom';

const OurPeople = () => {
  const navigate = useNavigate();
  const associateId = useSelector((state) => state.user.userDetails?.PrimaryAssociates);
  const userRole = useSelector((state) => state.user.userDetails?.role);
  console.log(userRole,associateId);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [associateInfo, setAssociateInfo] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (userRole === "client" && associateId) {
      const fetchAssociateInfo = async () => {
        try {
          const response = await axios.get(`${ipv4}/associate/${associateId}`);
          setAssociateInfo(response.data.associate);
          setLoading(false);
        } catch (error) {
          console.error("Error fetching associate info:", error);
          setLoading(false);
        }
      };

      fetchAssociateInfo();
    } else {
      setLoading(false);
    }
  }, [associateId, userRole]);

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === OurPeopleData.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? OurPeopleData.length - 1 : prevIndex - 1
    );
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="OurPeople">
      <div className="OurPeople-tag">
        <div>
          <h1>Our Associates</h1>
        </div>
        <div className="Arrow">
          <div onClick={handlePrev}></div>
          <div onClick={handleNext}></div>
        </div>
      </div>
      <hr />
      <div className="carousel-container">
        <div className="carousel-inner">
          {userRole === "client" && associateInfo ? (
            <div className="GuyData">
              <div>
                <img src={associateInfo.userPicture} alt={associateInfo.name} />
              </div>
              <div>
                <h2>{associateInfo.firstName}{associateInfo.lastName}</h2>
                <p>{associateInfo.role}</p>
                <div className="icons">
                  <ul>
                    <li>
                      <img onClick={() => window.location.href = `mailto:${associateInfo.email}`} src={Envelope} alt="Envelope" />
                    </li>
                    <li>
                      <img onClick={() => window.open(associateInfo.linkedinLink, '_blank')} src={LinkedinLogo} alt="LinkedIn" />
                    </li>

                  </ul>
                </div>
                <div>
                  <a href={associateInfo.bookACall || '/contact'} className="btn" 
                   target="_blank" 
                   rel="noopener noreferrer"  
                  >
                    Book a Meeting
                  </a>
                </div>
              </div>
            </div>
          ) : (
            OurPeopleData.map((data, index) => (
              <div className="GuyData" key={index}>
                <div>
                  <img src={data.img} alt={data.name} />
                </div>
                <div>
                  <h2>{data.name}</h2>
                  <p>{data.role}</p>
                  <div className="icons">
                    <ul>
                      <li>
                        <img onClick={() => window.location.href = `mailto:${data.emailLink}`} src={Envelope} alt="Envelope" />
                      </li>
                      <li>
                        <img onClick={() => window.open(data.linkedinLink, '_blank')} src={LinkedinLogo} alt="LinkedIn" />
                      </li>
                    </ul>
                  </div>
                  <div>
                    <a href={data.bookLink || '/contact'} className="btn" 
                    target="_blank" 
                    rel="noopener noreferrer"                  
                    >
                      Book a Meeting
                    </a>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default OurPeople;
