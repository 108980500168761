import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Weather from "../../components/Weather/Weather";
import Trending from "../../components/Trending/Trending";
import Price from "../../components/Price/Price";
import Data from "../../components/Data/Data";
import Login from "../../components/Login/Login";
import Signup from "../../components/SignUp/SignUp";
import ForgotPassword from "../../components/ForgotPassword/ForgotPassword";
import OurPeople from "../../components/OurPeople/OurPeople";
import OurSolution from "../../components/OurSolution/OurSolution";
import Upload from "../../components/Upload/Upload";
import Assistant from "../../components/Assistant/Assistant";
import Gas from "../../components/Gas/Gas";
import Exchange from "../../components/Exchange/Exchange";
import Account from "../../components/Account/Account";
import Review from "../../components/Review/Review";
import Real from "../../components/Real/Real";
import Otp from "../../components/Login/Otp";
import { login, logout } from "../../redux/userSlice";
import "./Home.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Home = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const [showSignup, setShowSignup] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [showOtpComponent, setShowOtpComponent] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(isAuthenticated);
  const [selectedFileType, setSelectedFileType] = useState(null);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [reloadData, setReloadData] = useState(0);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    if (token && userDetails) {
      dispatch(login({ token, userDetails }));
      setIsLoggedIn(true);
    }
  }, [dispatch]);

  const handleSignupClick = () => {
    setShowSignup(true);
    setShowForgotPassword(false);
  };

  const handleLoginClick = () => {
    setShowSignup(false);
    setShowForgotPassword(false);
    setShowOtpComponent(false);
  };

  const handleForgotPasswordClick = () => {
    setShowForgotPassword(true);
    setShowSignup(false);
  };

  const handleLoginSuccess = () => {
    setIsLoggedIn(true);
    setShowForgotPassword(false);
  };

  const handleSignupSuccess = () => {
    setIsLoggedIn(true);
    setShowSignup(false);
    setShowOtpComponent(true);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem("token");
    localStorage.removeItem("userDetails");
    dispatch(logout());
  };

  const handleFileTypeSelected = (fileType) => {
    setSelectedFileType(fileType);
  };
  const handleFolderSelected = (folder) => {
    setSelectedFolder(folder);
  };

  return (
    <div className="home">
      <div className="side1">
        <Weather />
        <Trending />
        <Price />
        {/* <Gas /> */}
        <Exchange />
      </div>
      <div className="side2">
        {isLoggedIn ? <Data uploadedFileType={selectedFileType} reloadData={reloadData} uploadFolder={selectedFolder} /> : <Real />}
        <div className="below-div">
          <Upload
            onFileTypeSelected={handleFileTypeSelected}
            fileType={selectedFileType}
            folder={selectedFolder}
            onFileUploaded={() => setReloadData(reloadData + 1)}
          />
          <Assistant />
        </div>
      </div>
      <div className="side3">
        <div>
          {isLoggedIn ? (
            <>
              <Account onLogout={handleLogout} />
              <Review />
            </>
          ) : showSignup ? (
            <Signup
              onLoginClick={handleLoginClick}
              onSignupSuccess={handleSignupSuccess}
              showOtpComponent={handleSignupSuccess}
            />
          ) : showForgotPassword ? (
            <ForgotPassword onLoginClick={handleLoginClick} />
          ) : (
            <Login
              onSignupClick={handleSignupClick}
              onLoginSuccess={handleLoginSuccess}
              onForgotPasswordClick={handleForgotPasswordClick}
            />
          )}
        </div>
        <OurPeople />
        <OurSolution />
      </div>
      <ToastContainer position="top-center" autoClose={5000} />
    </div>
  );
};

export default Home;
