import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CareLeft from "../../assets/CareLeft.png";
import CareRight from "../../assets/CareRight.png";
import "./Review.css";
import ProgressBar from "../ProgressBar/Progressbar";
import { calculateProgress } from "../../utils/progressUtils";
import { updateProgress } from "../../actions/userActions";

const Review = () => {
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.user.userDetails);
  const progressPercentage = useSelector((state) => state.user.progressPercentage);

  useEffect(() => {
    if (userDetails) {
      const progress = calculateProgress(userDetails);
      dispatch(updateProgress({ progressPercentage: progress }));
    }
  }, [userDetails, dispatch]);

  return (
    <div className="Review">
      <div className="Price-tag">
        <div>
          <h1>Scorecards</h1>
        </div>
        <div className="Arrow">
          <div>
            {/* <img src={CareLeft} alt="Previous" /> */}
          </div>
          <div>
            {/* <img src={CareRight} alt="Next" /> */}
          </div>
        </div>
      </div>
      <hr />
      <p>To improve your score, ask SG Assistant to book a meeting.</p>
      <div className="progress">
        <ProgressBar percentage={progressPercentage} />
      </div>
    </div>
  );
};

export default Review;
