import React, { useState, useContext } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import { login } from "../../redux/userSlice";
import Google from "../../assets/google.png";
import Apple from "../../assets/apple.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { ImSpinner9 } from "react-icons/im";
import "./Login.css";
import { ipv4 } from "../../utils/config";
import { DataContext } from "../../contextAPI/mycontext";
const Login = ({ onSignupClick, onLoginSuccess, onForgotPasswordClick }) => {
  const { setLoginData, Logindata } = useContext(DataContext);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    if (!formData.email || !formData.password) {
      toast.error("Email or password is invalid, please fill correctly", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    setLoading(true);
    try {
      const response = await axios.post(ipv4 + "/signin", formData);
      if (response.status === 200) {
        const { token, userDetails } = response.data;
        setLoginData(response.data)
        dispatch(login({ token, userDetails }));
        localStorage.setItem("token", token);
        localStorage.setItem("userDetails", JSON.stringify(userDetails));

        if (typeof onLoginSuccess === "function") {
          onLoginSuccess();
        }
      } else {
        setLoading(false);
        handleErrorResponse(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error signing in:", error);
      handleErrorResponse(error.response?.data?.message || "An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleErrorResponse = (message) => {
    if (message === "Invalid email or password") {
      window.alert("Your email is incorrect", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (message === "Invalid password") {
      window.alert("Your password is incorrect", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
    } else {
      window.alert(message, {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    handleSubmit();
  };
  return (
    <div className="Login">
      <h1>Login</h1>
      <hr />
      <form onSubmit={handleFormSubmit}>
        <div>Email</div>
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required={true}
        />
        <div>Password</div>
        <div className="password-container">
          <input
            type={showPassword ? "text" : "password"}
            name="password"
            value={formData.password}
            onChange={handleChange}
            required={true}
          />
          <span
            className="password-toggle-icon"
            onClick={togglePasswordVisibility}
          >
            <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
          </span>
        </div>
      </form>
      <div className="pass-btn">
        <div>
          <input
            type="checkbox"
            id="remember"
            name="remember"
            value="remember"
          />
          <label htmlFor="remember"> Remember me</label>
        </div>
        <div onClick={onForgotPasswordClick}>Forgot Password</div>
      </div>
      <div className="login-btn">
        <div className="login" onClick={handleSubmit}>
          {loading ? (
          <ImSpinner9 className="spinner" style={{ color: "white", fontSize: "18px" }} />
          ) : (
            "Login"
          )}
        </div>
        <div className="signup" onClick={onSignupClick}>
          Signup
        </div>
      </div>
      {/* <ToastContainer /> */}
    </div>
  );
};

export default Login;
