import React from "react";
import S1 from "../../assets/SG 5.0 webview Wealth Partners Banner.gif";
import "./Wealth.css";

const Wealth = () => {
  return (
    <div className="Wealth">
      {/* <h1>SG Wealth Partners</h1> */}
      <div className="WealthPic flex center">
        <img src={S1} alt="" />
      </div>

      <div className="great section-headings-center">
        <p>
          {" "}
          The Great Succession as already started. Over $60 Billion in Canada
          will define the greatest wealth transfer in history. In less then 17
          years more adults will be in care facilities than in the workforce! 
          <br />
          <br />
          Do you have a PLAN?
        </p>
      </div>

      <hr />

      <div className="people">
        <h2>Our People</h2>
        <p>
          Sentry Group Associates are as individual as you are. We have diverse
          backgrounds and experience, and each is educated and licensed in
          various areas of financial services. We then bring those minds to the
          boardroom table as a collaborative team to solve problems and
        </p>
      </div>
      <div className="wealthBtn">
        <a href="/contact" className="btn">
          Book a call
        </a>
      </div>
    </div>
  );
};

export default Wealth;
