import React, { useState, useEffect } from "react";
import "./Trending.css";
import CareLeft from "../../assets/CareLeft.png";
import CareRight from "../../assets/CareRight.png";

const API_KEY = "81f375faa799409fa80509d10654da8d";

const Trending = () => {
  const [news, setNews] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [category, setCategory] = useState("rss-business");

  const [feedItems, setFeedItems] = useState([]);

  // const categories = {
  //   regional: "regional",
  //   national: "national",
  //   business: "business",
  //   technology: "technology",
  //   sports: "sports",
  // };

  const categories = {
   "rss-world" : "world",
    "rss-canada": "national",
   "rss-business" : "business",
    "rss-technology" : "technology",
    "rss-sports" : "sports",
  };

  useEffect(() => {
    // fetchNews();
    fetchRSS();
  }, [category]);

  const fetchNews = async () => {
    try {
      const response = await fetch(
        `https://newsapi.org/v2/everything?q=${category}&apiKey=${API_KEY}`
      );
      if (!response.ok) {
        throw new Error(`Failed to fetch news for category: ${category}`);
      }
      const data = await response.json();
      const filteredArticles = data.articles
        .filter((article) => article.urlToImage)
        .map((article) => ({
          ...article,
          description: article.description
            ? article.description.split(". ")[0] + "."
            : "",
        }));
      setNews(filteredArticles);
    } catch (error) {
      console.error(error.message);
      setNews([]);
    }
  };

  const fetchRSS = async () => {
    try {
      const response = await fetch(`/fetch-rss/:${category}`);
      const rssText = await response.text();
  
      // Parse the RSS XML
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(rssText, 'text/xml');
  
      // Get items from the feed
      const items = xmlDoc.getElementsByTagName('item');
      const feedArray = Array.from(items).map((item) => {
        return {
          title: item.getElementsByTagName('title')[0].textContent,
          link: item.getElementsByTagName('link')[0].textContent,
          description: item.getElementsByTagName('description')[0].textContent,
        };
      });
  
      setFeedItems(feedArray);
    } catch (error) {
      console.error('Error fetching the RSS feed:', error);
    }
  };

  console.log("rss news: ", feedItems);

  const parseDescription = (description) => {
    // Regex to extract the image URL and the rest of the description
    const imgRegex = /<img.*?src=['"](.*?)['"].*?>/;
    const imgMatch = description.match(imgRegex);
    const imgUrl = imgMatch ? imgMatch[1] : null;
  
  // Remove the image tag and the <p> tags from the description
  const text = description
    .replace(imgRegex, '')       // Remove <img> tag
    .replace(/<\/?p>/g, '')      // Remove <p> and </p> tags
    .trim();

  return { imgUrl, text };
  };

  const handleNext = () => {
    setCategory((prevCategory) => {
      const categoryKeys = Object.keys(categories);
      const currentIndex = categoryKeys.indexOf(prevCategory);
      const nextIndex = (currentIndex + 1) % categoryKeys.length;
      setCurrentIndex(0);
      return categoryKeys[nextIndex];
    });
  };

  const handlePrev = () => {
    setCategory((prevCategory) => {
      const categoryKeys = Object.keys(categories);
      const currentIndex = categoryKeys.indexOf(prevCategory);
      const prevIndex =
        (currentIndex - 1 + categoryKeys.length) % categoryKeys.length;
      setCurrentIndex(0);
      return categoryKeys[prevIndex];
    });
  };

  const truncateText = (text) => {
    const maxLength = 50;
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    } else {
      return text;
    }
  };

  return (
    <div className="Trending">
      <div className="Trending-tag">
        <div>
          <h1>{category.charAt(4).toUpperCase() + category.slice(5)} News</h1>
        </div>
        <div className="Arrow">
          <div onClick={handlePrev}>
            <img src={CareLeft} alt="Previous" />
          </div>
          <div onClick={handleNext}>
            <img src={CareRight} alt="Next" />
          </div>
        </div>
      </div>
      <hr />
      {/* <div className="carousel-container">
        <div
          className="carousel-inner"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {news.map((article, index) => (
            <div className="TrendingData" key={index}>
              <div className="image-container">
                <img src={article.urlToImage} alt="Article" />
              </div>
              <div className="paragraph-container">
                <p>{truncateText(article.description)}</p>
              </div>
            </div>
          ))}
        </div>
      </div> */}
      {/* <div className="additional-trending">
        {news.slice(10, 13).map((article, index) => (
          <div className="TrendingData" key={index + news.length}>
            <div className="image-container">
              <img src={article.urlToImage} alt="Article" />
            </div>
            <div className="paragraph-container">
              <p>{truncateText(article.description)}</p>
            </div>
          </div>
        ))}
      </div> */}
            <div className="additional-trending">
        {feedItems.slice(10, 13).map((article, index) => (
          <div className="TrendingData" key={index + feedItems.length}>
            <div className="image-container">
            <a href={article.link}><img src={parseDescription(article.description).imgUrl} alt="Article" /></a>
            </div>
            <div className="paragraph-container">
            <a href={article.link}><p>{truncateText(article.title)}</p></a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Trending;
