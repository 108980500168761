import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { ImSpinner9 } from "react-icons/im";
import { setSelectedFileType, setSelectedFolder } from "../../redux/userSlice";
import { ipv4 } from "../../utils/config";
import Files from "../../assets/CurrencyCircleDollar.png";
import "./Tax.css";
import banner from "../../assets/SG 5.0 userview Tax & Accounting Banner.gif";

import ontprob from "../../assets/ontprob.svg"
import fintrac  from "../../assets/fintrac.svg"
import sage from "../../assets/sage.svg"
import servcan from "../../assets/servcan.webp";

const Tax = ({ onFileTypeClick, reloadData }) => {
  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFolder, setSelectedFolderState] = useState(null);
  const userId = useSelector((state) => state.user.userDetails?.user_id);
  const userRole = useSelector((state) => state.user.userDetails?.role);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setIsLoading(true);
        let response;
        if (userRole === "Admin") {
          response = await axios.get(`${ipv4}/get_Allfiles`);
        } else {
          response = await axios.post(`${ipv4}/getUserFilesById`, {
            user_id: userId,
          });
        }
        setIsLoading(false);
        setUserData(response.data);
      } catch (error) {
        console.error("Error fetching user data:", error);
        setIsLoading(false);
      }
    };

    fetchUserData();
  }, [userRole, userId, reloadData]);

  const getFileCount = (category) => {
    if (!userData) return 0;

    if (Array.isArray(userData)) {
      return userData.reduce(
        (total, user) => total + (user[category]?.files?.length || 0),
        0
      );
    } else {
      return userData[category]?.files?.length || 0;
    }
  };


  const handleFolderSelect = (folderName) => {
    setSelectedFolderState(folderName);
    dispatch(setSelectedFolder(folderName));
    console.log("Folder selected:", folderName);
  };

  const handleFileTypeClick = (fileType) => {
    dispatch(setSelectedFileType(fileType));
    if (typeof onFileTypeClick === 'function') {
      onFileTypeClick(fileType, userData);
    } else {
      console.log("File type clicked:", fileType);
    }
  };

  const handleClick = () => {
    let clickTimer;
    return () => {
      if (clickTimer) {
        clearTimeout(clickTimer);
        handleFileTypeClick("Taxes");
      } else {
        clickTimer = setTimeout(() => {
          handleFolderSelect("Taxes");
          clickTimer = null;
        }, 300);
      }
    };
  };

  if (isLoading) {
    return (
      <div className="loader">
        <ImSpinner9 className="spinner" />
      </div>
    );
  }

  return (
    <div className="DataFiles">
      {/* <h1>Taxes</h1>
      <div className="FilesDataContainer">
        <div 
          className={`FileItem ${selectedFolder === "Taxes" ? "selected-folder" : ""}`}
          onClick={handleClick()}
        >
          <div className="FileCount">{getFileCount("Taxes")}</div>
          <div className="FileImageContainer">
            <div>
              <img src={Files} alt="Taxes" className="FileImage" />
            </div>
            <div className="FileName">
              <h1>Taxes</h1>
            </div>
          </div>
        </div>
      </div> */}
      <div className="details-container flex col gap-1">
        <img src={banner} alt="" />
        <div className="details-container-text">
          <h2>Optimize your tax strategy, review now to stay ahead!
          </h2>
          <p>Maximize your savings.
          </p>
          <a href="/contact" className="btn">Schedule a call to refine your corporate tax strategy</a>
        </div>
      </div>
      <div className="links-container">
        <div className="link-1 flex center">
        <a target="_blank" href="https://www.ontario.ca"><img style={{background: "black"}} src={ontprob} alt="" /></a>
        </div>
        <div className="link-2 flex center">
        <a target="_blank" href="https://www.canada.ca/en/revenue-agency/services/e-services/cra-login-services.html"><img src={fintrac} alt="" /></a>
        </div>
        <div className="link-3 flex center">
        <a href="/resources" className="more-links-item">More Links &nbsp; <i className="fas fa-chevron-right fa-2x"></i></a>
        </div>
        <div className="link-4 flex center">
        <a target="_blank" href="https://www.sage.com/ca/"><img src={sage} alt="" /></a>
        </div>
        <div className="link-5 flex center">
        <a target="_blank" href="https://www.canada.ca/en/employment-social-development/corporate/portfolio/service-canada.html"><img src={servcan} alt="" /></a>
        </div>
      </div>
    </div>
  );
};

export default Tax;