import React from "react";
import "./AboutUs.css";

const AboutUs = () => {
  return (
    <div className="AboutUs">
      <h6>About US</h6>
      <div className="AboutPara">
        <p>
          Sentry Group Associates are as individual as you are. We have diverse
          backgrounds and experience, and each is educated and licensed in
          various areas of financial services.
        </p>
        <p>
          We then bring those minds to the boardroom table as a collaborative
          team to solve problems and design solutions to help every client meet
          their goals and objectives.
        </p>
      </div>
      <div className="c">
        <a href="/contact" className="btn">
          Book a call
        </a>
      </div>
    </div>
  );
};

export default AboutUs;
