import React from "react";
import "./Header.css";
import logo from "../../assets/logo.png";

const Header = () => {
  return (
    <header className="header">
      <div className="logo">
        <a href="/">
          <img src={logo} alt="SentryGroup" />
        </a>
      </div>
      <nav className="nav">
        <a href="/">Home</a>
        <a href="/services">Services</a>
        <a href="/community">Community</a>
        <a href="/news">News</a>
        <a href="/videos">Videos</a>
        <a href="/contact">Contact</a>
        <a href="/resources">Resources</a>
      </nav>
      <div className="social-icons">
        <a target="_blank" href="https://www.facebook.com/SentryGroup.ca2024/">
          <i className="fab fa-facebook"></i>
        </a>
        <a target="_blank" href="https://www.instagram.com/sentrygroup.ca/">
          <i className="fab fa-instagram"></i>
        </a>
        <a target="_blank" href="https://www.youtube.com/@sgSentryGroup">
          <i className="fab fa-youtube"></i>
        </a>
        {/* <a href="#">
          <i className="fab fa-twitter"></i>
        </a> */}
        <a
          target="_blank"
          href="https://www.linkedin.com/company/sentry-group-motivating-success/"
        >
          <i className="fab fa-linkedin"></i>
        </a>
        <a
          target="_blank"
          href="https://sentrygroup1.sharepoint.com/SitePages/Home.aspx"
        >
          <i class="fa-brands fa-microsoft"></i>
        </a>
      </div>
    </header>
  );
};

export default Header;
