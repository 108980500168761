import Guy from "../../assets/Guy.png";

export const OurPeopleData = [
  {
    id: 1,
    img: Guy,
    name: "Douglas J Cerson CFP, FMA, CEA",
    role: "Founder and Chair",
    linkedinLink:'https://www.linkedin.com',
    emailLink: 'dougcerson@sentrygroup.ca',
    bookLink:"/contact"
  },
 
];
