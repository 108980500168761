import React, { useState } from "react";
import "./ResourcesData.css";

import calculatorSoup from "../../assets/calculator-soup.png"
import dinky from "../../assets/dinky.png"
import szam from "../../assets/szam.png"
import manulife from "../../assets/manulife.png"
import newport from "../../assets/newport.svg"
import sgreot from "../../assets/sgreot.png"
import sgredt from "../../assets/sgredt.png"
import westpac from "../../assets/westpac.png"
import olympia from "../../assets/olympia.svg"
import wrsbc from "../../assets/wrsbc.svg"
import comhub from "../../assets/comhub.png"
import accctr from "../../assets/accctr.svg"
import edckw from "../../assets/edckw.svg"
import gkwcc from "../../assets/gkwcc.png"
import kwfc from "../../assets/kwfc.png"
import innoguelph from "../../assets/innoguelpg.svg"
import boc from "../../assets/boc.svg"
import statscan from "../../assets/statscan.svg"
import bloomberg from "../../assets/bloomberg.svg"
import yfin from "../../assets/yfin.svg"
import cafc from "../../assets/cafc.svg"
import fintrac  from "../../assets/fintrac.svg"
import aon from "../../assets/aon.png"
import marsh from "../../assets/marsh_rgb_w.svg"
import wtw from "../../assets/wtw.svg"
import aig from "../../assets/aig.svg"
import zurich from "../../assets/zurich.svg"
import allianz from "../../assets/allianz-logo.svg"
import chubb from "../../assets/chubb.png"
import libmut from "../../assets/libmut.svg"
import trvls from "../../assets/trvls.png"
import rma from "../../assets/rma.svg"
import canlife from "../../assets/canlife.svg"
import bmo from "../../assets/bmo.png"
import sunlife from "../../assets/sunlife.svg"
import indall from "../../assets/indall.svg"
import emplife from "../../assets/emplife.svg"
import coop from "../../assets/co-op.svg"
import intact from "../../assets/intact.svg"
import aviva from "../../assets/aviva.svg"
import dj from "../../assets/dj.svg"
import ontprob from "../../assets/ontprob.svg"
import profile from "../../assets/profile.svg"
import xero from "../../assets/xero.png"
import sage from "../../assets/sage.svg"
import freshbooks from "../../assets/freshbooks.svg"
import wave from "../../assets/wave.svg"
import zoho from "../../assets/zoho.svg"
import kashoo from "../../assets/kashoo.svg"
import qbo from "../../assets/qbo.svg"
import rbc from "../../assets/rbc.svg"
import td from "../../assets/td.png"
import scotia from "../../assets/scotia.svg"
import bmo2 from "../../assets/bmo.svg"
import cibc from "../../assets/cibc.svg"
import nbc from "../../assets/nbc.png"
import hsbc from "../../assets/hsbc.png"
import lauretian from "../../assets/lauretian.svg"
import meridian from "../../assets/meridian.svg"
import duca from "../../assets/duca.svg"
import alterna from "../../assets/alterna.png"
import firston from "../../assets/firston.svg"
import coastcap from "../../assets/coastcap.svg"
import libro from "../../assets/libro.svg"
import vancity from "../../assets/vancity.svg"
import servus from "../../assets/servus.png"
import affinity from "../../assets/affinity.svg"
import conexus from "../../assets/conexus.svg"
import coastal from "../../assets/coastal.svg"
import connect from "../../assets/connect.png"

const ResourcesData = () => {

  return (
    <div className="ServicesPage">
        <div className="ResourcesGrid">
            <div className="resource-1">
                <h4>Wealth Planning</h4>
                <div className="resource-items-container">
                    <div className="resource-item flex">
                        <p>InvCalc</p>
                        <a target="_blank" href="https://www.calculatorsoup.com/">
                            <img src={calculatorSoup} alt="" />
                        </a>
                    </div>
                    <div className="resource-item flex">
                        <p>SavCalc</p>
                        <a target="_blank" href="https://www.calculatorsoup.com/">
                            <img src={calculatorSoup} alt="" />
                        </a>
                    </div>
                    <div className="resource-item flex">
                        <p>LoanCalc</p>
                        <a target="_blank" href="https://www.dinkytown.net/java/rrsp-loan-calculator-canadian.html">
                            <img src={dinky} alt="" />
                        </a>
                    </div>
                    <div className="resource-item flex">
                        <p>MortCalc</p>
                        <a target="_blank" href="https://www.calculatorsoup.com/">
                            <img src={calculatorSoup} alt="" />
                        </a>
                    </div> 
                </div>
            </div>
            <div className="resource-2">
                <h4>Portfolio Management</h4>
                <div className="resource-items-container">
                    <div className="resource-item flex">
                        <p>SZAM</p>
                        <a target="_blank" href="http://szamcapital.com/">
                            <img src={szam} alt="" />
                        </a>
                    </div>
                    {/* <div className="resource-item flex">
                        <p>SGREDII</p>
                        <a target="_blank" href="http://szamcapital.com/">
                            <img src={szam} alt="" />
                        </a>
                    </div>
                    <div className="resource-item flex">
                        <p>SegFunds
                        </p>
                        <a target="_blank" href="http://szamcapital.com/">
                            <img src={szam} alt="" />
                        </a>
                    </div> */}
                    <div className="resource-item flex">
                        <p>Manulife
                        </p>
                        <a target="_blank" href="http://www.manulife.com/">
                            <img style={{background: "black"}} src={manulife} alt="" />
                        </a>
                    </div>
                    <div className="resource-item flex">
                        <p>NewLons
                        </p>
                        <a target="_blank" href="https://newportprivatewealth.ca">
                            <img src={newport} alt="" />
                        </a>
                    </div>
                    <div className="resource-item flex">
                        <p>SGREOT
                        </p>
                        <a target="_blank" href="https://www.smvcapitalmarkets.com/Company/sgreot">
                            <img src={sgreot} alt="" />
                        </a>
                    </div>
                    <div className="resource-item flex">
                        <p>SGREDT
                        </p>
                        <a target="_blank" href="https://www.smvcapitalmarkets.com/Company/sgredtrust">
                            <img src={sgredt} alt="" />
                        </a>
                    </div>
                    <div className="resource-item flex">
                        <p>15MKT
                        </p>
                        <a target="_blank" href="http://szamcapital.com/">
                            <img src={szam} alt="" />
                        </a>
                    </div>
                    <div className="resource-item flex">
                        <p>WestPac
                        </p>
                        <a target="_blank" href="https://www.westernpacifictrust.com">
                            <img style={{background: "black"}} src={westpac} alt="" />
                        </a>
                    </div>
                    <div className="resource-item flex">
                        <p>Olympia
                        </p>
                        <a target="_blank" href="https://www.olympiatrust.com">
                            <img src={olympia} alt="" />
                        </a>
                    </div>
                    <div className="resource-item flex">
                        <p>WRSBC
                        </p>
                        <a target="_blank" href="https://www.waterlooregionsmallbusiness.com/">
                            <img src={wrsbc} alt="" />
                        </a>
                    </div>
                    <div className="resource-item flex">
                        <p>ComHub
                        </p>
                        <a target="_blank" href="https://www.communitech.ca">
                            <img style={{background: "black"}} src={comhub} alt="" />
                        </a>
                    </div> 
                    <div className="resource-item flex">
                        <p>AccCtr
                        </p>
                        <a target="_blank" href="https://www.acceleratorcentre.com">
                            <img src={accctr} alt="" />
                        </a>
                    </div>
                    <div className="resource-item flex">
                        <p>EDCKW
                        </p>
                        <a target="_blank" href="https://www.waterlooedc.ca">
                            <img style={{background: "black"}} src={edckw} alt="" />
                        </a>
                    </div>
                    <div className="resource-item flex">
                        <p>GKWCC
                        </p>
                        <a target="_blank" href="https://www.greaterkwchamber.com">
                            <img src={gkwcc} alt="" />
                        </a>
                    </div>
                    <div className="resource-item flex">
                        <p>KWCF
                        </p>
                        <a target="_blank" href="https://www.kwcf.ca">
                            <img src={kwfc} alt="" />
                        </a>
                    </div> 
                    <div className="resource-item flex">
                        <p>InnoGuelph
                        </p>
                        <a target="_blank" href="https://www.innovationguelph.ca">
                            <img src={innoguelph} alt="" />
                        </a>
                    </div>
                    <div className="resource-item flex">
                        <p>BoC
                        </p>
                        <a target="_blank" href="https://www.bankofcanada.ca">
                            <img src={boc} alt="" />
                        </a>
                    </div>
                    <div className="resource-item flex">
                        <p>StatsCan
                        </p>
                        <a target="_blank" href="www.statcan.gc.ca">
                            <img src={statscan} alt="" />
                        </a>
                    </div>
                    <div className="resource-item flex">
                        <p>Bloomberg
                        </p>
                        <a target="_blank" href="https://www.bloomberg.com">
                            <img src={bloomberg} alt="" />
                        </a>
                    </div> 
                    <div className="resource-item flex">
                        <p>YFin
                        </p>
                        <a target="_blank" href="https://www.finance.yahoo.com">
                            <img src={yfin} alt="" />
                        </a>
                    </div>
                    <div className="resource-item flex">
                        <p>CAFC
                        </p>
                        <a target="_blank" href="https://antifraudcentre-centreantifraude.ca/index-eng.htm">
                            <img style={{background: "black"}} src={cafc} alt="" />
                        </a>
                    </div>
                    <div className="resource-item flex">
                        <p>FINTRAC
                        </p>
                        <a target="_blank" href="https://fintrac-canafe.canada.ca/intro-eng">
                            <img src={fintrac} alt="" />
                        </a>
                    </div> 
                </div>
            </div>
            <div className="resource-3">
                <h4>Insurance Management</h4>
                <div className="resource-items-container">
                    <div className="resource-item flex">
                        <p>Aon
                        </p>
                        <a target="_blank" href="https://www.aon.com">
                            <img src={aon} alt="" />
                        </a>
                    </div>
                    <div className="resource-item flex">
                        <p>Marsh
                        </p>
                        <a target="_blank" href="https://www.marsh.com">
                            <img style={{background: "black"}} src={marsh} alt="" />
                        </a>
                    </div>
                    <div className="resource-item flex">
                        <p>WTW
                        </p>
                        <a target="_blank" href="https://www.willistowerswatson.com">
                            <img src={wtw} alt="" />
                        </a>
                    </div>
                    <div className="resource-item flex">
                        <p>Gallagher
                        </p>
                        <a target="_blank" href="https://www.ajg.com">
                            <img src={aig} alt="" />
                        </a>
                    </div>
                    <div className="resource-item flex">
                        <p>Zurich
                        </p>
                        <a target="_blank" href="https://www.zurich.com">
                            <img src={zurich} alt="" />
                        </a>
                    </div>
                    <div className="resource-item flex">
                        <p>Allianz
                        </p>
                        <a target="_blank" href="https://www.allianz.com">
                            <img src={allianz} alt="" />
                        </a>
                    </div>
                    <div className="resource-item flex">
                        <p>Chubb
                        </p>
                        <a target="_blank" href="https://www.chubb.com">
                            <img src={chubb} alt="" />
                        </a>
                    </div>
                    <div className="resource-item flex">
                        <p>LibMut
                        </p>
                        <a target="_blank" href="https://www.libertymutual.com">
                            <img src={libmut} alt="" />
                        </a>
                    </div>   
                    <div className="resource-item flex">
                        <p>Trvls
                        </p>
                        <a target="_blank" href="https://www.travelers.com">
                            <img src={trvls} alt="" />
                        </a>
                    </div>
                    <div className="resource-item flex">
                        <p>RMA
                        </p>
                        <a target="_blank" href="https://www.rmahq.org">
                            <img src={rma} alt="" />
                        </a>
                    </div>
                    <div className="resource-item flex">
                        <p>Manulife
                        </p>
                        <a target="_blank" href="https://www.manulife.ca">
                            <img style={{background: "black"}} src={manulife} alt="" />
                        </a>
                    </div>
                    <div className="resource-item flex">
                        <p>CanLife
                        </p>
                        <a target="_blank" href="https://www.canadalife.com">
                            <img src={canlife} alt="" />
                        </a>
                    </div>
                    <div className="resource-item flex">
                        <p>BMO
                        </p>
                        <a target="_blank" href="https://www.bmo.com/insurance">
                            <img src={bmo} alt="" />
                        </a>
                    </div>
                    <div className="resource-item flex">
                        <p>SunLife
                        </p>
                        <a target="_blank" href="https://www.sunlife.ca">
                            <img src={sunlife} alt="" />
                        </a>
                    </div>
                    <div className="resource-item flex">
                        <p>GWL
                        </p>
                        <a target="_blank" href="https://www.greatwestlife.com">
                            <img src={canlife} alt="" />
                        </a>
                    </div>
                    <div className="resource-item flex">
                        <p>IndAll
                        </p>
                        <a target="_blank" href="https://www.ia.ca">
                            <img src={indall} alt="" />
                        </a>
                    </div>  
                    <div className="resource-item flex">
                        <p>EmpLife
                        </p>
                        <a target="_blank" href="https://www.empire.ca">
                            <img src={emplife} alt="" />
                        </a>
                    </div>  
                    <div className="resource-item flex">
                        <p>Co-op
                        </p>
                        <a target="_blank" href="https://www.cooperators.ca">
                            <img src={coop} alt="" />
                        </a>
                    </div>  
                    <div className="resource-item flex">
                        <p>Intact
                        </p>
                        <a target="_blank" href="https://www.intact.ca">
                            <img src={intact} alt="" />
                        </a>
                    </div>  
                    <div className="resource-item flex">
                        <p>Aviva
                        </p>
                        <a target="_blank" href="https://www.avivacanada.com">
                            <img src={aviva} alt="" />
                        </a>
                    </div>  
                    <div className="resource-item flex">
                        <p>Dj
                        </p>
                        <a target="_blank" href="https://www.desjardins.com">
                            <img style={{background: "black"}} src={dj} alt="" />
                        </a>
                    </div>  
                </div>
            </div>
            <div className="resource-4">
                <h4>Estate Planning</h4>
                <div className="resource-items-container">
                    <div className="resource-item flex">
                        <p>TGS
                        </p>
                        <a target="_blank" className="flex center" href="https://www.thegreatsuccession.com/">
                            The Great Succession
                        </a>
                    </div>  
                    <div className="resource-item flex">
                        <p>Canada.ca
                        </p>
                        <a target="_blank" href="https://www.canada.ca/en/employment-social-development/corporate/seniors-forum-federal-provincial-territorial/will-funeral-plan.html">
                            <img src={fintrac} alt="" />
                        </a>
                    </div>  
                    <div className="resource-item flex">
                        <p>OntProb
                        </p>
                        <a target="_blank" href="https://www.ontario.ca/page/apply-probate-estate">
                            <img style={{background: "black"}} src={ontprob} alt="" />
                        </a>
                    </div>    
                </div>
            </div>
            <div className="resource-5">
                <h4>Tax & Accounting</h4>
                <div className="resource-items-container">
                    <div className="resource-item flex">
                        <p>CRA
                        </p>
                        <a target="_blank" className="flex center" href="https://www.canada.ca/en/revenue-agency/services/e-services/e-services-businesses/gst-hst-netfile.html">
                            <img src={fintrac} alt="" />
                        </a>
                    </div>  
                    <div className="resource-item flex">
                        <p>ProFile
                        </p>
                        <a target="_blank" href="https://profile.intuit.ca/free-trial/">
                            <img src={profile} alt="" />
                        </a>
                    </div>  
                    <div className="resource-item flex">
                        <p>Xero
                        </p>
                        <a target="_blank" href="https://www.xero.com/ca/">
                            <img src={xero} alt="" />
                        </a>
                    </div>   
                    <div className="resource-item flex">
                        <p>Sage
                        </p>
                        <a target="_blank" href="https://www.sage.com/ca/">
                            <img src={sage} alt="" />
                        </a>
                    </div>  
                    <div className="resource-item flex">
                        <p>FreshBooks
                        </p>
                        <a target="_blank" href="https://www.freshbooks.com">
                            <img style={{background: "black"}} src={freshbooks} alt="" />
                        </a>
                    </div>   
                    <div className="resource-item flex">
                        <p>WaveAcct
                        </p>
                        <a target="_blank" className="flex center" href="https://www.waveapps.com">
                            <img src={wave} alt="" />
                        </a>
                    </div>  
                    <div className="resource-item flex">
                        <p>ZohoBooks
                        </p>
                        <a target="_blank" href="https://www.zoho.com/ca/books/">
                            <img style={{background: "black"}} src={zoho} alt="" />
                        </a>
                    </div>  
                    <div className="resource-item flex">
                        <p>Kashoo
                        </p>
                        <a target="_blank" href="https://www.kashoo.com">
                            <img src={kashoo} alt="" />
                        </a>
                    </div>   
                    <div className="resource-item flex">
                        <p>QBO
                        </p>
                        <a target="_blank" className="flex center" href="https://quickbooks.intuit.com/ca/">
                            <img src={qbo} alt="" />
                        </a>
                    </div>  
                    <div className="resource-item flex">
                        <p>CRA
                        </p>
                        <a target="_blank" href="https://www.canada.ca/en/revenue-agency/services/e-services/cra-login-services.html">
                            <img src={fintrac} alt="" />
                        </a>
                    </div>  
                    <div className="resource-item flex">
                        <p>ServOnt
                        </p>
                        <a target="_blank" href="www.ontario.ca">
                            <img style={{background: "black"}} src={ontprob} alt="" />
                        </a>
                    </div>   
                    <div className="resource-item flex">
                        <p>ServCan
                        </p>
                        <a target="_blank" className="flex center" href="https://www.canada.ca/en/employment-social-development/corporate/portfolio/service-canada.html">
                            <img src={fintrac} alt="" />
                        </a>
                    </div>  
                </div>
            </div>
            <div className="resource-6">
                <h4>Business Services</h4>
                <div className="resource-items-container"> 
                </div>
            </div>
            <div className="resource-7">
                <h4>Financing & Morgage</h4>
                <div className="resource-items-container">
                    <div className="resource-item flex">
                        <p>RBC
                        </p>
                        <a target="_blank" className="flex center" href="https://www.rbc.com">
                            <img src={rbc} alt="" />
                        </a>
                    </div>  
                    <div className="resource-item flex">
                        <p>TD
                        </p>
                        <a target="_blank" href="https://www.td.com">
                            <img src={td} alt="" />
                        </a>
                    </div>  
                    <div className="resource-item flex">
                        <p>Scotiabank
                        </p>
                        <a target="_blank" href="https://www.scotiabank.com">
                            <img src={scotia} alt="" />
                        </a>
                    </div>   
                    <div className="resource-item flex">
                        <p>BMO
                        </p>
                        <a target="_blank" href="https://www.bmo.com">
                            <img src={bmo2} alt="" />
                        </a>
                    </div>  
                    <div className="resource-item flex">
                        <p>CIBC
                        </p>
                        <a target="_blank" href="https://www.cibc.com">
                            <img src={cibc} alt="" />
                        </a>
                    </div>   
                    <div className="resource-item flex">
                        <p>NBC
                        </p>
                        <a target="_blank" className="flex center" href="https://www.nbc.ca">
                            <img src={nbc} alt="" />
                        </a>
                    </div>  
                    <div className="resource-item flex">
                        <p>HSBC
                        </p>
                        <a target="_blank" href="https://www.hsbc.ca">
                            <img src={hsbc} alt="" />
                        </a>
                    </div>  
                    <div className="resource-item flex">
                        <p>Laurentian
                        </p>
                        <a target="_blank" href="https://www.laurentianbank.ca">
                            <img src={lauretian} alt="" />
                        </a>
                    </div>   
                    <div className="resource-item flex">
                        <p>Dj
                        </p>
                        <a target="_blank" className="flex center" href="https://www.desjardins.com">
                            <img style={{background: "black"}} src={dj} alt="" />
                        </a>
                    </div>  
                    <div className="resource-item flex">
                        <p>Meridian
                        </p>
                        <a target="_blank" href="https://www.meridiancu.ca">
                            <img src={meridian} alt="" />
                        </a>
                    </div>  
                    <div className="resource-item flex">
                        <p>DUCA
                        </p>
                        <a target="_blank" href="https://www.duca.com">
                            <img src={duca} alt="" />
                        </a>
                    </div>   
                    <div className="resource-item flex">
                        <p>Alterna
                        </p>
                        <a target="_blank" className="flex center" href="https://www.alterna.ca">
                            <img src={alterna} alt="" />
                        </a>
                    </div>  
                    <div className="resource-item flex">
                        <p>FirstON
                        </p>
                        <a target="_blank" href="https://www.firstontario.com">
                            <img src={firston} alt="" />
                        </a>
                    </div>   
                    <div className="resource-item flex">
                        <p>CoastCap
                        </p>
                        <a target="_blank" className="flex center" href="https://www.coastcapitalsavings.com">
                            <img style={{background: "black"}} src={coastcap} alt="" />
                        </a>
                    </div>  
                    <div className="resource-item flex">
                        <p>Libro
                        </p>
                        <a target="_blank" href="https://www.libro.ca">
                            <img src={libro} alt="" />
                        </a>
                    </div>  
                    <div className="resource-item flex">
                        <p>Vancity
                        </p>
                        <a target="_blank" href="https://www.vancity.com">
                            <img src={vancity} alt="" />
                        </a>
                    </div>   
                    <div className="resource-item flex">
                        <p>Servus
                        </p>
                        <a target="_blank" className="flex center" href="https://www.servus.ca">
                            <img src={servus} alt="" />
                        </a>
                    </div>  
                    <div className="resource-item flex">
                        <p>Affinity
                        </p>
                        <a target="_blank" href="https://www.affinitycu.ca">
                            <img src={affinity} alt="" />
                        </a>
                    </div>  
                    <div className="resource-item flex">
                        <p>Conexus
                        </p>
                        <a target="_blank" href="https://www.conexus.ca">
                            <img src={conexus} alt="" />
                        </a>
                    </div>   
                    <div className="resource-item flex">
                        <p>Coastal
                        </p>
                        <a target="_blank" className="flex center" href="https://www.cccu.ca">
                            <img src={coastal} alt="" />
                        </a>
                    </div>  
                    <div className="resource-item flex">
                        <p>Connect
                        </p>
                        <a target="_blank" className="flex center" href="https://www.connectfirstcu.com">
                            <img style={{background: "black"}} src={connect} alt="" />
                        </a>
                    </div>  
                </div>
            </div>
            <div className="resource-8">
                <h4>Other Useful Links</h4>
                <div className="resource-items-container">  
                </div>
            </div>
            <div className="resource-9"></div>
        </div>
    </div>
  );
};

export default ResourcesData;